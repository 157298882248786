import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, PaginationSimple } from "../components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import slugifyPost from "../../slugifyPost"

const SingleWritingMagazinePage = ({ data, pageContext }) => {
  const {
    // contentful_id,
    textTitle,
    author,
    magazineName,
    publisher,
    magazinePublished,
    textSize,
  } = data.contentfulMagazine

  const image = getImage(data.contentfulMagazine.magazineImage)

  const { previous, next } = pageContext

  const prevTitle = previous ? previous.textTitle : null
  const nextTitle = next ? next.textTitle : null
  // const prevRow = previous ? previous.contentful_id : null
  // const nextRow = next ? next.contentful_id : null
  // az id-vel túl csúnya link, azért csináltam a névvel inkább
  const prevRow = previous ? slugifyPost(previous.author.authorFullName) : null
  const nextRow = next ? slugifyPost(next.author.authorFullName) : null
  const allItemPage = "/writing/magazines/1"
  const oneItemPage = "/writing/magazine/"

  // console.log("author", author)

  return (
    <Wrapper>
      <Layout>
        <div className="section-center">
          <h1>{textTitle}</h1>
          <h2>{author.authorFullName}</h2>
          <h3>{magazineName}</h3>
          {/* <div
            className="info"
            dangerouslySetInnerHTML={{
              __html: magazineText.childMarkdownRemark.html,
            }}
          ></div> */}

          {/* <a href={magazines_moly} target="_blank" rel="noreferrer" className="btn">
            <h3>moly.hu: {magazines_title}</h3>
          </a> */}

          <h4 className="publisher">{publisher.publisherName}</h4>
          <h4 className="date-page">
            {magazinePublished} | {textSize} oldal
          </h4>

          <GatsbyImage image={image} className="img" alt="Magazine cover" />
        </div>
        <PaginationSimple
          oneItemPage={oneItemPage}
          allItemPage={allItemPage}
          prevTitle={prevTitle}
          nextTitle={nextTitle}
          prevRow={prevRow}
          nextRow={nextRow}
          pageContext={pageContext}
        />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  background: var(--clr-light-5);
  padding-top: 9rem;
  padding-bottom: 0rem;
  color: var(--clr-primary-4);

  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }

  .section-center {
    h2 {
      text-align: center;
      padding-bottom: 2rem;
    }

    h3 {
      ${"" /* text-align: center; */}
      padding-bottom: 1rem;
      padding-top: 1rem;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;
    }

    h4 {
      text-align: center;
      text-transform: uppercase;
    }

    .publisher {
      font-weight: bold;
    }

    .date-page {
      text-transform: lowercase;
      padding-bottom: 2rem;
      font-weight: bold;
    }

    .info {
      padding-left: 10%;
      padding-bottom: 2rem;
      color: var(--clr-grey-1);
      border-left: 2px solid var(--clr-primary-5);
    }
  }

  .img {
    border-radius: var(--radius);
    transition: var(--transition);
  }

  a {
    ${"" /* display: block; */}
    width: auto;
    text-align: center;
    ${"" /* padding: 1rem 1rem; */}
    margin: 0 auto;
    ${"" /* margin-top: 1rem; */}
    color: var(--clr-light-3);
  }

  .btn {
    color: var(--clr-primary-22);
  }

  .btn:hover {
    color: var(--clr-primary-1);
  }

  @media (min-width: 768px) {
    .section-center {
      width: 70%;
    }
  }
  @media (min-width: 992px) {
    .section-center {
      width: 50%;
    }
  }
  @media (min-width: 1200px) {
    .section-center {
      width: 40%;
    }
  }
`

export const query = graphql`
  query($recordId: String!) {
    contentfulMagazine(contentful_id: { eq: $recordId }) {
      contentful_id
      textTitle
      author {
        authorFullName
      }

      magazineName
      publisher {
        publisherName
      }
      magazinePublished(formatString: "YYYY-MM")
      textSize
      magazineImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
      }
    }
  }
`

export default SingleWritingMagazinePage
